@font-face {
  font-family: 'SourceSansPro';
  font-weight: 300;
  font-style: normal;
  font-display: auto;
  src: url('fonts/SourceSans-Light.woff') format('woff');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-style: normal;
  font-display: auto;
  src: url('fonts/SourceSans-Light.woff') format('woff');
}
@font-face {
  font-family: 'SourceSansPro';
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  src: url('fonts/SourceSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  src: url('fonts/SourceSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'SourceSansPro';
  font-weight: 600;
  font-style: normal;
  font-display: auto;
  src: url('fonts/SourceSans-Semibold.woff') format('woff');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-style: normal;
  font-display: auto;
  src: url('fonts/SourceSans-Semibold.woff') format('woff');
}
@font-face {
  font-family: 'SourceSansPro';
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  src: url('fonts/SourceSans-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  src: url('fonts/SourceSans-Bold.woff') format('woff');
}
