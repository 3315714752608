html,
body {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    overflow: scroll;
    font-size: 16px;
    font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

* {
    box-sizing: border-box;
}
